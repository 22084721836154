import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import "../styles/page/ecruit.css";
import ImageRecruit from "images/icon/arrow-rights.svg";
import Facebook from "images/facebook-f.svg";
import BlockIcon from "../components/homepage/block-icon";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
const ecruit = () => {
  const { allStrapiRecruit, strapiRecruitPage } = useStaticQuery(graphql`
    query {
      allStrapiRecruit {
        nodes {
          link
          id
          time
          title
          quantity
          place
          description {
            data {
              id
              description
              childrenMarkdownRemark {
                html
              }
            }
          }
        }
      }
      strapiRecruitPage {
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80
                webpOptions: {quality: 80}
                placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <GatsbySeo title="Tuyển dụng" />
      <div className="about">
          <div>
             <GatsbyImage image={strapiRecruitPage.image.localFile.childImageSharp.gatsbyImageData} alt={strapiRecruitPage.image.alternativeText} className="project__baner"/>
            <div className="container">
              <div className="baner__slider Home__siteTitle">
                <div>
                  <Link title="Trang-chu" to="/" className="baner__title">
                    Trang Chủ
                  </Link>{" "}
                  <span className="baner__title">/ {strapiRecruitPage.title}</span>
                </div>
                <h2 className="baner__desc">{strapiRecruitPage.title}</h2>
              </div>
            </div>
          </div>
      </div>
      <div className="container">
        <div className="recruit__main home__siteTitle">
          <div>
            <h2>Đang Tuyển dụng</h2>
          </div>
          <div className="recruit__fb">
            <a
              target="_blank"
              href="https://www.facebook.com/alphaland.com.vn"
              title="Tuyển dụng"
              className="recruit__style"
              rel="noreferrer"
            >
              <img src={Facebook} className="icon_buttonfb" />
              <h4>Fanpage tuyển dụng</h4>
              <img src={ImageRecruit} className="icon_buttons" />
            </a>
          </div>
        </div>
        <div className="recruit__events">
          <div>
            <Accordion>
              {allStrapiRecruit.nodes.map((item, index) => (
                <AccordionItem
                  sx={{
                    color: "#f58420",
                    borderRadius: "4px",
                    borderWidth: "1px",
                    borderColor: "#f58420",
                    boxShadow: "1px",
                  }}
                  className="recruit__events__time"
                  key={index}
                >
                  <AccordionButton
                    sx={{ padding: "20px 20px" }}
                    _hover={{ background: "white" }}
                    _expanded={{ bg: "white", color: "#f58420" }}
                    _focus={{ boxShadow: "none" }}
                    className="recruit__content"
                  >
                    <Box
                      flex="1"
                      display={{ base: "block", md: "block" }}
                      textAlign="left"
                    >
                      <div>
                        <p>Số lượng: {item.quantity}</p>
                        <h3>Tp. {item.place}</h3>
                        <h2>{item.title}</h2>
                      </div>
                    </Box>
                    <Box
                      flex="1"
                      display={{ base: "none", md: "block" }}
                      textAlign="left"
                    >
                      <div className="ecruit__title">
                        <p>Địa Điểm</p>
                        <h2>Tp. {item.place}</h2>
                      </div>
                    </Box>
                    <Box
                      flex="1"
                      display={{ base: "none", md: "block" }}
                      textAlign="left"
                    >
                      <div className="ecruit__title">
                        <p>Hạn nộp hồ sơ</p>
                        <h2>{item.time}</h2>
                      </div>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel
                    sx={{ background: "#F3FAFF" }}
                    className="recruit__content__desc"
                    pb={4}
                  >
                    <div className="recruit__ul" dangerouslySetInnerHTML={{ __html: item.description.data?.childrenMarkdownRemark[0].html}}/> 
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <BlockIcon/>
    </Layout>
  );
};

export default ecruit;
